import { render, staticRenderFns } from "./switcher.vue?vue&type=template&id=65adf08b&scoped=true"
import script from "./switcher.vue?vue&type=script&lang=js"
export * from "./switcher.vue?vue&type=script&lang=js"
import style0 from "./switcher.vue?vue&type=style&index=0&id=65adf08b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65adf08b",
  null
  
)

export default component.exports