
import {mapGetters} from "vuex";

export default {
    name: 'premiumRequired',
    render: function() {
        if (this.$auth.loggedIn && this.isPremiumBuyer) {
            return this.$slots.default;
        }

        return null;
    },
    computed: {
        ...mapGetters(['isPremiumBuyer']),
    },
};
