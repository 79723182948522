
export default {
    props: ['value', 'label', 'id'],
    methods: {
        updateValue(event) {
            this.$emit('input', event.target.checked);

            if (this.value !== undefined) {
                event.target.checked = this.value;
            }
        },
    }
}
