
export default {
    props: ['days_published'],

    computed: {
        listed_days() {
            switch (this.days_published) {
                case 0:
                    return "Just listed"
                case 1:
                    return "1 day ago";
                default:
                    return this.days_published + " days ago";
            }
        },
        animation_delay() {
            const overall_delay = Math.random() * 5;
            const firststar_delay = 0.15 + overall_delay;
            const secondstar_delay = 0.3 + overall_delay;
            return [`${firststar_delay}s`, `${overall_delay}s`, `${secondstar_delay}s`];
        }
    },
}
