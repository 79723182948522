import { render, staticRenderFns } from "./index.vue?vue&type=template&id=06a48315"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=06a48315&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PropertiesMap: require('/app/components/properties/map.vue').default,PropertiesAdvancedFilters: require('/app/components/properties/advanced-filters.vue').default,PropertiesPropertyListingItem: require('/app/components/properties/property-listing-item.vue').default})
